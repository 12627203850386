import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// lib
import { getIsXSDevice } from 'lib/utils';

// components
import { Link } from 'gatsby';
import ArrowRightSvg from 'images/arrow-right.svg';
import ArrowRightLightSvg from 'images/arrow-right-light.svg';

// styles
import './section.scss';

// constants
const imageCache = {};

// component class
class HomeSection extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node,
    buttonText: PropTypes.string,
    showButtonArrow: PropTypes.bool,
    image: PropTypes.object,
    mobileImage: PropTypes.object,
    tight: PropTypes.bool,
    lightDescriptionText: PropTypes.bool,
    lightButton: PropTypes.bool,
    linkUrl: PropTypes.string,
    onClick: PropTypes.func,
  };

  imageInstance = null;

  state = {
    isImageLoaded: false,
  };

  componentDidMount() {
    const { image, mobileImage } = this.props;

    const isXSDevice = getIsXSDevice();
    const isImageInCache = this.getIsImageInCache();

    const imageObject = isXSDevice && mobileImage ? mobileImage : image;

    if (!isImageInCache) {
      this.imageInstance = new Image();
      this.imageInstance.src = (imageObject || {}).src;
      this.imageInstance.onload = this.handleImageLoad;
    }
  }

  componentWillUnmount() {
    if (this.imageInstance) {
      this.imageInstance.onload = null;
    }
  }

  getIsImageInCache = () => {
    const { image } = this.props;

    return imageCache[image.src] || false;
  };

  getBackgroundStyles() {
    const { image, mobileImage } = this.props;

    const imageObject = image || {};
    const mobileImageObject = mobileImage || imageObject;

    return {
      desktop: {
        preview: { backgroundImage: `url("${imageObject.base64}")` },
        src: { backgroundImage: `url("${imageObject.src}")` },
      },
      mobile: {
        preview: { backgroundImage: `url("${mobileImageObject.base64}")` },
        src: { backgroundImage: `url("${mobileImageObject.src}")` },
      },
    };
  }

  handleImageLoad = () => {
    const { image } = this.props;

    imageCache[image.src] = true;

    this.setState({
      isImageLoaded: true,
    });
  };

  render() {
    const {
      title,
      children,
      buttonText,
      showButtonArrow,
      tight,
      lightDescriptionText,
      lightButton,
      linkUrl,
      onClick,
    } = this.props;

    const { isImageLoaded } = this.state;
    const isImageInCache = this.getIsImageInCache();

    const isImageVisible = isImageLoaded || isImageInCache;

    const backgroundStyles = this.getBackgroundStyles();

    const sectionClassNames = classNames(
      'home_section container-fluid d-flex justify-content-end',
      tight && 'home_section--tight',
    );

    const descriptionClassNames = classNames(
      'home_section_description home_section_description--wide',
      lightDescriptionText && 'font-weight-light',
    );

    const buttonClassNames = classNames(
      'home_section_button',
      lightButton && 'home_section_button--light',
    );

    const imagePreviewClassNames = classNames('home_section_image-preview', {
      'home_section_image-preview--hidden': isImageVisible,
    });

    return (
      <div className={sectionClassNames}>
        <div
          className={`${imagePreviewClassNames} home_section_image-preview--desktop`}
          style={backgroundStyles.desktop.preview}
        />
        <div
          className={`${imagePreviewClassNames} home_section_image-preview--mobile`}
          style={backgroundStyles.mobile.preview}
        />

        {isImageVisible && (
          <>
            <div
              className="home_section_image home_section_image--desktop"
              style={backgroundStyles.desktop.src}
            />
            <div
              className="home_section_image home_section_image--mobile"
              style={backgroundStyles.mobile.src}
            />
          </>
        )}

        <div className="home_section_gradient" />

        <div className="home_section_content">
          <h2 className="home_section_title">{title}</h2>

          <p className={descriptionClassNames}>{children}</p>

          <MaybeLinkOrButton
            to={linkUrl}
            onClick={onClick}
            className={buttonClassNames}
          >
            {buttonText}
            {showButtonArrow && <ArrowIcon />}
          </MaybeLinkOrButton>
        </div>
      </div>
    );
  }
}

function ArrowIcon() {
  return (
    <Fragment>
      <span className="home_section_button_icon d-none d-sm-inline">
        <ArrowRightSvg />
      </span>
      <span className="home_section_button_icon d-sm-none">
        <ArrowRightLightSvg />
      </span>
    </Fragment>
  );
}

function MaybeLinkOrButton({ to, onClick, ...restProps }) {
  if (to) {
    return <Link to={to} onClick={onClick} {...restProps} />;
  }

  if (onClick) {
    return <button onClick={onClick} {...restProps} />;
  }

  return <span {...restProps} />;
}

export default HomeSection;
