import React, { Component } from 'react';

// vendor
import Slider from 'react-slick';
import { graphql, Link, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';

// images
import ArrowIcon from 'images/arrow-right-black.svg';

// styles
import './post-slider.scss';

class PostSlider extends Component {
  isDragging = false;

  getSliderConfig() {
    const posts = this.getPosts();

    return {
      speed: 500,
      slidesToShow: 3,
      centerPadding: 0,
      prevArrow: <SliderArrow to="prev" />,
      nextArrow: <SliderArrow to="next" />,
      infinite: posts.length > 3,
      centerMode: true,
      swipeToSlide: true,
      autoplay: true,
      autoplaySpeed: 5000,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
            infinite: posts.length > 2,
          },
        },

        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
            infinite: posts.length > 1,
          },
        },
      ],
    };
  }

  getPosts() {
    const { data } = this.props;

    return (
      (
        (((data.allContentfulHomePostCarousel || {}).edges || [])[0] || {})
          .node || {}
      ).posts || []
    );
  }

  handleAfterChange = () => {
    this.isDragging = false;
  };

  handleBeforeChange = () => {
    this.isDragging = true;
  };

  handleItemClick = e => {
    if (this.isDragging) {
      e.preventDefault();
    }
  };

  render() {
    const posts = this.getPosts();
    const sliderConfig = this.getSliderConfig();

    return (
      <div className="post-slider">
        <Slider
          {...sliderConfig}
          beforeChange={this.handleBeforeChange}
          afterChange={this.handleAfterChange}
        >
          {posts.map(post => (
            <SliderItem
              key={post.slug}
              onClick={this.handleItemClick}
              post={post}
            />
          ))}
        </Slider>
      </div>
    );
  }
}

function SliderItem({ post, onClick }) {
  const { description, title, headerImage, slug, categories } = post;
  const image = (headerImage || {}).fluid;
  const descriptionExcerpt = ((description || {}).childMarkdownRemark || {})
    .excerpt;
  const linkUrl = `/resource-library/${slug}`;

  return (
    <Link
      onClick={onClick}
      draggable={false}
      to={linkUrl}
      className="slider_post-item"
    >
      {image && (
        <Img
          draggable={false}
          fluid={image}
          className="slider_post-item_image"
        />
      )}

      <div className="slider_post-item_gradient" />

      <div className="slider_post-item_post_content">
        <div className="d-flex justify-content-between">
          <div className="slider_post-item_tags-container">
            {categories.map(category => (
              <div key={category.slug} className="slider_post-item_tag">
                {(category || {}).name}
              </div>
            ))}
          </div>
        </div>

        <span className="slider_post-item_name">{title}</span>
        <div className="slider_post-item_description">{descriptionExcerpt}</div>
      </div>
    </Link>
  );
}

function SliderArrow({ to, onClick }) {
  const className = `slider-arrow slider-arrow--${to}`;

  return (
    <button onClick={onClick} className={className}>
      <ArrowIcon />
    </button>
  );
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulHomePostCarousel(limit: 1) {
          edges {
            node {
              posts {
                title
                slug
                categories {
                  name
                  slug
                }
                description {
                  childMarkdownRemark {
                    excerpt
                  }
                }
                headerImage {
                  fluid {
                    ...GatsbyContentfulFluid
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <PostSlider data={data} {...props} />}
  />
);
