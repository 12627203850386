import React, { Component } from 'react';
import PropTypes from 'prop-types';

// vendor
import { StaticQuery, graphql } from 'gatsby';

// components
import Img from 'gatsby-image';
import Layout from 'components/common/layout';
import Header from 'components/common/header';
import Section from 'components/home/section';
import SubscribeModal from 'components/post/subscribe/subscribe-modal';
import PostSlider from 'components/home/post-slider';

// styles
import 'stylesheets/home/index.scss';

// component class
class IndexPage extends Component {
  static propTypes = {
    data: PropTypes.object,
  };

  state = {
    isSubscribeModalOpen: false,
  };

  handleOpenSubscribeModal = () => {
    this.setState({ isSubscribeModalOpen: true });
  };

  handleCloseSubscribeModal = () => {
    this.setState({ isSubscribeModalOpen: false });
  };

  hookOpenSubscribe = this.handleOpenSubscribeModal;

  render() {
    const { data } = this.props;
    const { isSubscribeModalOpen } = this.state;

    const { edges: sectionEdges } = data.allContentfulHomePageSection;
    const logoImage = data.logoImage.childImageSharp.fluid;

    //const latestPost = data.allContentfulResourceLibrary.edges[0];
    //const latestPost = data.latestPost;

    return (
      <Layout>
        <Header showLogo styleType="white" />
        <main className="main" role="main">
          <div className="home_jumbotron container-fluid">
            <Img className="home_logo" fluid={logoImage} alt="trove" />
            <h1 className="home_title">The influencer business bible</h1>
            <PostSlider />
          </div>

          {sectionEdges.map(({ node }) => {
            const sectionHookMethod = this[node.hookMethod] || void 0;
            const showButtonArrow = !!(node.linkUrl || node.hookMethod);

            return (
              <Section
                key={node.id}
                title={node.title}
                buttonText={node.linkText}
                showButtonArrow={showButtonArrow}
                image={node.backgroundImage.fluid}
                mobileImage={
                  node.backgroundMobileImage && node.backgroundMobileImage.fluid
                }
                tight={!node.description}
                lightDescriptionText={true}
                lightButton={node.lightButton}
                linkUrl={node.linkUrl}
                onClick={sectionHookMethod}
              >
                {node.description}
              </Section>
            );
          })}
        </main>

        {isSubscribeModalOpen && (
          <SubscribeModal
            schemeName="newsletter"
            onClose={this.handleCloseSubscribeModal}
          />
        )}
      </Layout>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        latestPost: contentfulResourceLibrary(
          contentful_id: { eq: "KcCVhNrji0sW6Q2MoSuAu" }
        ) {
          title
          slug
        }
        allContentfulResourceLibrary(
          sort: { fields: [publishDate], order: DESC }
          limit: 1
        ) {
          edges {
            node {
              title
              slug
            }
          }
        }
        allContentfulHomePageSection(
          limit: 10
          sort: { fields: [order], order: ASC }
        ) {
          edges {
            node {
              id
              title
              description
              backgroundMobileImage {
                fluid(maxWidth: 576) {
                  ...GatsbyContentfulFluid
                }
              }
              backgroundImage {
                fluid(maxWidth: 1200) {
                  ...GatsbyContentfulFluid
                }
              }
              linkText
              linkUrl
              lightButton
              hookMethod
            }
          }
        }
        logoImage: file(relativePath: { eq: "home-logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => <IndexPage data={data} {...props} />}
  />
);
